import classNames from 'classnames/bind';
import styles from '@/styles/superstack/base/MatchingScoreLayer.module.scss';
const cx = classNames.bind(styles);

import useCompanyMatchingScores from '@/src/utils/hooks/superstack/useCompanyMatchingScores';
import IconCultureHeart from '@/asset/superstack/svgs/icon-culture-heart.svg';
import IconCultureHeartEmpty from '@/asset/superstack/svgs/icon-culture-heart-empty.svg';
import IconStacksHeart from '@/asset/superstack/svgs/icon-stacks-heart.svg';
import IconStacksHeartEmpty from '@/asset/superstack/svgs/icon-stacks-heart-empty.svg';
import useJobMatchingScores from '@/src/utils/hooks/superstack/useJobMatchingScores';

const JobMatchingScoreLayer = ({
  jobId = null,
  cultureOff = false,
  stacksOff = false,
  ...props
}) => {
  const { data: { data: { culture_score = null, stacks_score = null } = {} } = {} } =
    useJobMatchingScores(jobId);
  return (
    <MatchingScoreLayer
      culture_score={culture_score}
      stacks_score={stacks_score}
      cultureOff={cultureOff}
      stacksOff={stacksOff}
      {...props}
    />
  );
};

const CompanyMatchingScoreLayer = ({ companyId = null, ...props }) => {
  const { data: { data: { culture_score = null } = {} } = {} } =
    useCompanyMatchingScores(companyId);
  return (
    <MatchingScoreLayer
      culture_score={culture_score}
      stacks_score={null}
      cultureOff={false}
      stacksOff={true}
      {...props}
    />
  );
};

const MatchingScoreLayer = ({
  culture_score,
  stacks_score,
  cultureOff = false,
  stacksOff = false,
  ...props
}) => {
  return (
    <div className={cx('matching-score-layer')} {...props}>
      {!cultureOff && <CultureScoreSign cultureScore={culture_score} />}
      {!cultureOff && !stacksOff && <div className={cx('sep')} />}
      {!stacksOff && <StackScoreSign stackScore={stacks_score} />}
    </div>
  );
};

const CultureScoreSign = ({ cultureScore }) => {
  const Icon = cultureScore ? IconCultureHeart : IconCultureHeartEmpty;
  return (
    <div className={cx(['score-wrapper', 'culture', !cultureScore ? 'empty' : ''])}>
      <Icon />
      <p>컬처코드 {cultureScore ? cultureScore.toFixed(0) : '??'}% 일치</p>
    </div>
  );
};

const StackScoreSign = ({ stackScore }) => {
  const isEmpty = !stackScore && stackScore !== 0;
  const Icon = isEmpty ? IconStacksHeartEmpty : IconStacksHeart;
  return (
    <div className={cx(['score-wrapper', 'stacks', isEmpty ? 'empty' : ''])}>
      <Icon />
      <p>기술 스택 {isEmpty ? '??' : stackScore.toFixed(0)}% 일치</p>
    </div>
  );
};

export { JobMatchingScoreLayer, CompanyMatchingScoreLayer };
